import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { api } from "../../../config";
import axios from "axios";
import { push, graphql } from "gatsby";

import Spinner from "../../../components/spinner/spinner";
import Card from "../card";
import AccountLayout from "../account-layout";
import InternalPage from "../internal-page";
import Stripe from "../../../components/stripe/stripe";
import FormStatus from "../../../components/form/formStatus";
import { getUser, getAccessToken } from "../../../lib/auth";
import { Link } from "../../../lib/components/Link";
import Layout from "../../../components/layout/layout";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const Payment = ({ data: { ribbon, header, footer }, profile }) => {
  const [user, setUser] = React.useState(getUser());
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(null);

  const attachForm = (node) => {
    // _form = node;
  };

  const onSubmit = ({ token, error }) => {
    const _self = this;
    if (error) {
      setError(error.message);
      setLoading(false);
    }
    _self.props.updateStatus({
      cancelSuccess: "",
      cancelFailure: error.message,
    });
    setLoading(true);

    axios({
      method: "post",
      url: `${api.base}${api.updatePaymentMethod}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: {
        token: token.id,
      },
    }).then((res) => {
      if (res.data.error) {
        setError(profile.updatePaymentFail);
        setLoading(false);
        return _self.props.updateStatus({
          cancelSuccess: "",
          cancelFailure: profile.updatePaymentFail,
        });
      }

      setSuccess(profile.updatePaymentSuccess);
      setError(null);
      setLoading(false);

      _self.props.updateStatus({
        cancelSuccess: profile.updatePaymentSuccess,
        cancelFailure: "",
      });
    });
  };

  const onError = (err) => {
    setSuccess(profile.updatePaymentSuccess);
    setError("null");
    setLoading(false);
  };

  const account = "account - text";
  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="white-banner"
    >
      <AccountLayout currentPage="/account/subscription">
        <InternalPage titleText={account.changePaymentDetails} toast={""}>
          <Card className="wide_card">
            <div
              css={css`
                /* white-space: nowrap; */
                display: block;
                text-align: center;
                font-family: league-gothic, sans-serif;
                font-size: 20px;
                letter-spacing: normal;
                margin: 0px;
              `}
            >
              <div
                css={css`
                  height: 40px;
                  font-family: Barlow;
                  font-size: 32px;
                  font-weight: bold;
                  font-style: normal;
                  font-stretch: condensed;
                  line-height: 1.25;
                  letter-spacing: normal;
                  text-align: center;
                  color: #000;
                  padding-bottom: 40px;
                `}
              >
                {account.enterCreditCard}
              </div>
              <div
                css={css`
                  display: block;
                  margin: auto;
                  max-width: 500px;
                  @media (max-width: 767px) {
                    flex-direction: column;
                  }
                `}
              >
                <Spinner active={loading} />

                <Stripe
                  formStatus={<FormStatus error={error} success={success} />}
                  button={account.changePaymentDetails}
                  onSubmit={onSubmit}
                  onError={onError}
                  attachForm={attachForm}
                />
                <div
                  css={css`
                    display: flex;

                    @media (max-width: 767px) {
                      flex-direction: column;
                    }
                  `}
                >
                  <button
                    className={"header__nav-button button-hover"}
                    css={css`
                      margin-top: 12px;
                      min-width: 250px;
                      height: 63.6px;
                    `}
                    onClick={(e) => {
                      //handleSubmit(e);
                    }}
                  >
                    {account.changePaymentDetails}
                  </button>
                  <Link
                    to="/account/subscription"
                    css={css`
                      width: 250px;
                      margin-top: 12px;
                      height: 63.6px;

                      box-shadow: none;
                      background-image: none;
                      color: black;
                      font-family: Barlow;
                      font-size: 21px;
                      font-weight: bold;
                      font-style: normal;
                      font-stretch: condensed;
                      line-height: 63px;
                      letter-spacing: normal;
                      text-align: center;
                      border: none;
                      display: table;
                      &:hover {
                        cursor: pointer;
                      }
                      @media (max-width: 767px) {
                        order: 2;
                        width: 100%;
                      }
                    `}
                  >
                    {account?.subscriptionCancelButton?.cancelButton}
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </InternalPage>
      </AccountLayout>
    </Layout>
  );
};

export default Payment;
